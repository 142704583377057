import React, { Component } from 'react';
import * as S from './styles';
import transitionEvent from '../../helpers/transitionEventHelper';

class Accordion extends Component {
  state = {
    isOpened: false
  }

  getContentRef = ref => this.content = ref;
  getInnerContentRef = ref => this.innerContent = ref;

  handleCollapsibleClick = () => {
    this.content.style.height = `${this.content.offsetHeight}px`;

    requestAnimationFrame(() => {
      this.setState({ isOpened: !this.state.isOpened });
      this.content.style.height = this.state.isOpened ? `${this.innerContent.offsetHeight}px` : 0;
    });
  }

  componentDidMount() {
    this.content.addEventListener(transitionEvent, () => {
      if (this.state.isOpened) {
        this.content.style.height = 'auto';
      }
    });
  }

  render() {
    const { title, time, text } = this.props;

    return (
      <S.Accordion isActive={this.state.isOpened}>
        <S.Accordion.Header onClick={this.handleCollapsibleClick}>
          <S.Accordion.Title>{title}</S.Accordion.Title>
          <S.Accordion.Button isActive={this.state.isOpened} type="button" />
        </S.Accordion.Header>

        <S.Accordion.Body ref={this.getContentRef} >
          <S.Accordion.BodyContent ref={this.getInnerContentRef}>
            <S.Accordion.Text>{text}</S.Accordion.Text>
            <S.Accordion.Time>{time}</S.Accordion.Time>
          </S.Accordion.BodyContent>
        </S.Accordion.Body>
      </S.Accordion>
    );
  }
}

export default Accordion;
