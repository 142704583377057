import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../styles/globalStyles';
import theme from '../styles/theme';
import { graphql } from 'gatsby';

import Header from '../components/header';
import SEO from '../components/seo';

import * as S from '../components/experience/styles';
import * as L from '../components/layout/styles';

import Pattern from '../components/pattern';
import Accordion from '../components/accordion';
import { CVLink } from '../components/actions';

const Experience = ({ pageContext: { content } }) => {
  const data = content['experience'];

  return (
    <ThemeProvider theme={theme.experience}>
      <Fragment>
        <SEO />
        <GlobalStyle />
        <L.Page>
          <Header {...content.header }/>

          <L.Container>
            <Pattern />
            <CVLink />

            <S.Experience>
              <S.Experience.Title>{data.headings.experience}</S.Experience.Title>

              <S.Experience.Icon width="11px" height="10px" viewBox="0 0 11 10">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-712.000000, -232.000000)" fill="#EE6E65" fillRule="nonzero">
                    <g transform="translate(712.000000, 232.000000)">
                      <polygon transform="translate(5.500000, 5.000000) rotate(45.000000) translate(-5.500000, -5.000000) " points="-0.181721408 5.81723882 -0.11723882 3.81827859 11.1817214 4.18276118 11.1172388 6.18172141"></polygon>
                      <polygon transform="translate(5.500000, 5.000000) rotate(-45.000000) translate(-5.500000, -5.000000) " points="11.1172388 3.81827859 11.1817214 5.81723882 -0.11723882 6.18172141 -0.181721408 4.18276118"></polygon>
                    </g>
                  </g>
                </g>
              </S.Experience.Icon>

              <S.Experience.Subtitle>Over than 7 years of work experience in different fields — always passionate about my job and enjoy learning new things</S.Experience.Subtitle>

              <S.Experience.List>
                {data.experienceItems.map(item => (
                  <Accordion
                    title={`${item.title} / ${item.place}`}
                    time={item.date}
                    text={item.text}
                    key={item.title}
                  />
                ))}
              </S.Experience.List>
            </S.Experience>
          </L.Container>
        </L.Page>
      </Fragment>
    </ThemeProvider>
  )
};

export default Experience;

export const query = graphql`{
  pageData: markdownRemark(frontmatter: { type: { eq: "experience" } }) {
    frontmatter {
      content {
        title
      }
    }
  }
}
`;
