import styled from 'styled-components';
import { H1, P } from '../typography/styles';

const Experience = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  margin: '0 auto',
});

Experience.Title = styled(H1)(({ theme }) => ({
  position: 'relative',
  margin: '0 auto',
  textAlign: 'center',
  marginTop: '3rem',
  color: theme.primary,
}));

Experience.Icon = styled.svg(({ theme }) => ({
  width: '20px',
  height: '20px',
  backgroundColor: theme.white,
}));

Experience.Subtitle = styled(P)(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '500px',
  textAlign: 'center',
  marginBottom: '4rem',
  color: theme.primary,
}));

Experience.List = styled.ul({
  width: '100%',
  maxWidth: '820px',
  margin: '0 auto',
  padding: '0',
  listStyle: 'none',
});

export {
  Experience,
};
