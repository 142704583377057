import styled from 'styled-components';
import { P } from './../typography/styles';

const $buttonSize = '44px';
const $buttonMargin = '30px';
const $headerPadding = '1rem';
const $signWidth = '15px';
const $signHeight = '2px';

export const Accordion = styled.li(({ theme }) => ({
  width: '100%',
  color: theme.primary,
  border: `1px solid ${theme.primary}`,
  padding: '0',

  '& + &': {
    marginTop: '1rem'
  },

  '&:hover': {
    border: `1px solid ${theme.secondary}`,
  }
}));

Accordion.Header = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${$headerPadding}`,
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: 1.3,
  color: theme.primary,
  cursor: 'pointer',
}));

Accordion.Button = styled.button(({ theme, isActive }) => ({
  cursor: 'pointer',
  position: 'relative',
  flexShrink: 0,
  width: `${$buttonSize}`,
  height: `${$buttonSize}`,
  marginLeft: `${$buttonMargin}`,
  borderRadius: '100%',
  outline: 'none',
  background: 'transparent',
  border: 'none',

  '&:before, &:after': {
    position: 'absolute',
    left: `{(${$buttonSize - $signWidth}) / 2}`,
    width: `${$signWidth}`,
    height: `${$signHeight}`,
    backgroundColor: theme.primary,
    borderRadius: '10px',
    content: `''`,
    transition: 'backgroundColor .3s ease-in-out',
  },

  '&:after': {
    transform: isActive ? 'rotate(0)' :'rotate(90deg)',
    transition: 'transform .2s ease-in-out',
  },
}));

Accordion.Body = styled.div(({ theme }) => ({
  height: 0,
  paddingLeft: '1rem',
  paddingRight: `calc(${$buttonSize} + ${$buttonMargin})`,
  overflow: 'hidden',
  lineHeight: '1.5em',
  color: theme.primary,
  transition: 'height .5s ease',
  willChange: 'height',

  'a': {
    color: theme.secondary,
    textDecoration: 'underline',

    '&:hover': {
      fontWeight: 700,
    }
  }
}));

Accordion.BodyContent = styled.div(({ theme }) => ({
  paddingBottom: $headerPadding,
}));

Accordion.Time = styled.time(({ theme }) => ({
  display: 'block',
  marginTop: '1rem',
  color: theme.secondary,
  fontWeight: 600,
  fontSize: '.86rem',
}));

Accordion.Title = styled.h2(({ theme }) => ({
  fontSize: '1.2rem',
  padding: 0,
  color: theme.primary,
}));

Accordion.Text = styled(P)(({ theme }) => ({
  padding: 0,
  color: theme.primary,
}));
